import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Breadcrumbs from "./breadcrumbs";
import Banner from "./banner";
import Concept from "./concept";
import Challenges from "./challenges";
import Paltform from "./paltform";
import Projectlog from "./projectlog";
import Outcomes from "./outcomes";
import Techstack from "./techstack";
import Result from "./result";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {baseURL} from "../../utils/constant";


function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true)
        const response = await axios.get(`${baseURL}/case-study/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false)
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);

    return (
        <Layout>
            {isLoaded ?
                <div className="services_banner tekrevol_banner text-center">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="loading ">
                                    <img src="https://www.tekrevol.com/Tek-Spinner.gif"
                                         alt="loader"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                :
                <>
                    <Breadcrumbs link1="TekRevol" link2="Case Studies" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Concept data={userData}/>
                    <Challenges data={userData}/>
                    <Paltform data={userData}/>
                    <Projectlog data={userData}/>
                    <Outcomes data={userData}/>
                    <Techstack data={userData}/>
                    <Result data={userData}/>

                </>
            }
        </Layout>
    );
}

export default Index;