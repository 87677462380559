import React from 'react';
import {checkData} from "../../helper";
import {Col, Container, Row} from "react-bootstrap";
import {mediaBaseURL} from "../../utils/constant";

function Outcomes({data}) {

    return (
        <div className="outcomes_section" style={{
            backgroundImage: `linear-gradient(180deg, ${checkData(data, 'bg_light_color')} 0%, ${checkData(data, 'bg_light_color')} 80%, #ffffff 80%)`
        }}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={10} lg={8} xl={8} xxl={8} className="m-auto text-center img_positions">
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'outcomes_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'outcomes_title')}/>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Outcomes;

