import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Concept({data}) {
    return (
        <>
            <div className="concept_section">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={10} lg={8} xl={8} xxl={8} className="coloum-set m-auto text-center" style={{
                            background: `${checkData(data, 'bg_dark_color')}`
                        }}>
                            <img
                                src={`${mediaBaseURL}${checkData(data, 'concept_image')}`}
                                className="img-fluid"
                                alt={checkData(data, 'concept_title')}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="p-100">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs={12} md={10} lg={10} xl={10} xxl={10} className="m-auto text-center">
                            <h2>{checkData(data, 'concept_title')}</h2>
                            <div>{HtmlParser(checkData(data, 'concept_description'))}</div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>

    );
}

export default Concept;
